<script>
/**
 * LOAD DEPENDENCIES
 * --------------------------------------------------------------------------------
 */
import { computed, ref } from "vue";
import { useStore } from "vuex";

/**
 * CONSTANTS
 * --------------------------------------------------------------------------------
 */
const pageSize = 10;

/**
 * REFS
 * --------------------------------------------------------------------------------
 **/
const search = ref('');
const page  = ref(1);
const year  = ref('');

/**
 * HELPERS
 * --------------------------------------------------------------------------------
 */
const reducer = (accumulator, currentValue) => accumulator + currentValue;

/**
 * VUE COMPONENT
 * --------------------------------------------------------------------------------
 */
export default {
  props: ["country"],
  setup(props) {
    const store  = useStore();
    const source = store.state.sources.find(
      (d) => d.country == props.country.id
    );

    /**
     * COMPUTED
     * --------------------------------------------------------------------------------
     */
    const contracts = computed(() => store.state.contracts[props.country.id]);

    const years = computed(() => {
        if (!contracts.value) return null;
        return [...new Set(contracts.value.map((d) => d.year))]
          .sort()
          .reverse();
      });
    
    const filteredContracts = computed(() => {
        if (!contracts.value) return [];
        if (!search.value && !year.value) return contracts.value;

        const fields = ["buyer", "items", "provider", "title", "id"];
        const sr = search.value.trim().toLowerCase();

        // optimize later
        return contracts.value.filter((contract) => {
          const values = fields
            .map((f) => contract[f] ? contract[f].trim().toLowerCase() : '')
            .filter((f) => f)
            .join(", ");
          return (
            values.search(sr) !== -1 &&
            (year.value ? contract.year == year.value : 1)
          );
        });
      });


    const visibleContracts = computed(() => {
        if (!filteredContracts.value) return [];

        const realPage = page.value - 1;
        const step = realPage * pageSize;

        return filteredContracts.value.slice(step, step + pageSize);
      });

    const buyers = computed(() => [
      ...new Set(filteredContracts.value.map((d) => d.buyer)),
    ]);

    const providers = computed(() => [
      ...new Set(filteredContracts.value.map((d) => d.provider)),
    ]);

    const currency = computed(() => [
      ...new Set(filteredContracts.value.map((d) => d.currency)),
    ]);

    const amount = computed( () => {
      return  filteredContracts.value && filteredContracts.value.length ? filteredContracts.value.filter(d => d.amount).map(d => +d.amount).reduce(reducer) : 0;
    });

    const pages = computed( () => Math.ceil(filteredContracts.value.length / pageSize) || 1);

    /**
     * TEMPLATE ELEMENTS
     * --------------------------------------------------------------------------------
     **/
    return {
      // store
      source,
      format : store.getters.formatNumber,
      search,
      // pagination
      page,
      pages,
      year,
      pageSize,
      // computed
      years,
      filteredContracts,
      contracts,
      visibleContracts,
      buyers,
      providers,
      currency,
      amount
    };
  },
};
</script>



<template>
  <section v-if="contracts">
    <!--title -->
    <div class="row">
      <div class="col-12">
        <h1>Contratos</h1>
        <p class="mb-4 pb-4">
          Estos son los contratos relacionados con la respuesta y atención al
          VIH que el gobierno pone a disposición del público. Puedes hacer la
          búsqueda por nombre de la institución, el proveedor o el producto
          comprado.
        </p>
      </div>
    </div>

    <!--buscador -->
    <div class="row mb-4 pb-4">
      <div class="col-12">
        <form>
          <div class="row">
            <div class="col-sm-10">
              <input
                v-model="search"
                class="od_search"
                type="text"
                name="item"
                placeholder="Buscar contrato"
              />
            </div>

            <div class="col-sm-2">
              <select v-model="year" v-on:change="page = 1">
                <option value="">Todos</option>
                <option v-for="(y, i) in years" :key="`opt-y-${i}`" :value="y">
                  {{ y }}
                </option>
              </select>
            </div>
          </div>
        </form>
      </div>
    </div>

    <!--montos -->
    <div class="row mb-4 pb-4">
      <div class="col">
        <h6>Monto total de contratos ({{ currency.join(',') }})</h6>
        <h3 class="od_contracts_amount">${{ format(amount) }}</h3>
      </div>
      <div class="col">
        <h6>Contratos</h6>
        <h3 class="od_contracts_amount">{{ filteredContracts.length }}</h3>
      </div>
      <div class="col">
        <h6>Provedores</h6>
        <h3 class="od_contracts_amount">{{ providers.length }}</h3>
      </div>
      <div class="col">
        <h6>Instituciones</h6>
        <h3 class="od_contracts_amount">{{ buyers.length }}</h3>
      </div>
    </div>
    <!--resultados -->
    <div class="row mt-4 pt-4">
      <div class="col-12">
        <!--
        <p>15 resultados para <em>"tratamiento"</em></p>-->
      </div>
      <div class="col-12">
        <div class="table">
          <table class="table">
            <thead>
              <tr>
                <th>Descripción</th>
                <th>Institución</th>
                <th>Proveedor</th>
                <th>Fecha</th>
                <th>Monto</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(con, i) in visibleContracts" :key="`contract-${i}`">
                <td>
                  <strong>
                    <router-link :to="{ name: 'Contract', params: { id: con.id }}">{{con.title}}</router-link>
                  </strong>
                </td>
                <td>{{ con.buyer }}</td>
                <td>{{ con.provider }}</td>
                <td>{{ con.contract || con.registration_date }}</td>
                <td>${{ format(con.amount) }} ({{ con.currency }})</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4 offset-8">
        <form>
          <p class="od_pagination text-right">
            <span>
              <span v-if="page > 1" class="btn_prev_next">
                <a v-on:click.prevent="page = page - 1">Anterior &lt;</a>
              </span>
              <input v-model="page" type="number" min="1" :max="pages" />
            </span>
            /<span>{{ pages }} </span>
            <span v-if="page < pages" class="btn_prev_next">
              <a v-on:click.prevent="page = page + 1">Siguiente &gt;</a>
            </span>
          </p>
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="ip_separator bottom"></div>
        <p v-if="source">
          Fuente:
          <a :href="source.url" target="_blank">{{ source.source_name }}</a>
        </p>
      </div>
    </div>
  </section>
</template>